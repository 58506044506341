import React from 'react'
import Header from './Header'
import Footer from './Footer'
import '../icons';

const Layout = ({ children, headerClassName = "bg-white text-rickshaw-blue border-rickshaw-blue" }) => {
    return (
        <div>
            <Header className={headerClassName} />
            <main>{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
