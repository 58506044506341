import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <footer className='flex flex-col justify-between max-w-screen-xl px-4 mx-auto my-12 text-sm text-center text-gray-500 md:text-left lg:px-0 items:start lg:items-center lg:flex-row'>
            <div className='flex flex-col mb-4 md:mb-0 '>
                <h1 className='mb-1 text-lg font-bold text-rickshaw-blue'>Rickshaw</h1>
                <p>An Anomaly Company</p>
            </div>
            <nav>
                <ul className='flex flex-col md:flex-row'>
                    <li className='mb-2 md:ml-4 hover:text-rickshaw-blue md:mb-0'>
                        <Link to='/privacy'>Privacy Statement</Link>
                    </li>
                    <li className='mb-2 md:mb-0 md:ml-4 hover:text-rickshaw-blue'>
                        <Link to='/terms'>Terms of Service</Link>
                    </li>
                    <li className='md:ml-4'>&copy; Anomaly Software</li>
                </ul>
            </nav>
        </footer>
    );
};
export default Footer;
