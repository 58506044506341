import React, { useState } from 'react'
import { Link } from 'gatsby'
// import useMediaQuery from '../hooks/useMediaQuery'
import Icon from '../images/logo-rickshaw.svg';

const Header = ({ className }) => {

    const [accordionOpen, setAccordionOpen] = useState(false);
    // const breakpointCheck = useMediaQuery('(max-width: 768px)');

    const headerClasses = "flex flex-col items-center lg:justify-between p-4 lg:pb-8 lg:flex-row lg:mx-auto lg:max-w-screen-lg";
    const headerMobileOpenClasses = "min-h-screen fixed z-50 bg-white w-full text-rickshaw-blue";
    
    return (
        <div className={className}>
            <header className={accordionOpen ? `${headerClasses} ${headerMobileOpenClasses}`:`${headerClasses}`}>
                <div className="flex flex-row justify-between w-full lg:w-auto">
                    <Link className="flex items-center justify-center" to="/">
                        <Icon className="h-8 mr-2 fill-current"/>
                        <h1 translate="no" className="flex items-center h-12 text-xl font-bold">Rickshaw</h1>
                    </Link>
                    <button aria-label="Menu" className="lg:hidden" onClick={() => setAccordionOpen((o) => !o)}>
                        <svg className={`${!accordionOpen ? 'block' : 'hidden'} h-8`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <svg className={`${accordionOpen ? 'block' : 'hidden'} h-8`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <nav className={`${accordionOpen ? 'block' : 'hidden'} w-full lg:w-auto lg:block`}>
                    <ul className="flex flex-col justify-between mt-8 text-xl ml-14 lg:text-base lg:mt-0 lg:text-center lg:flex-row lg:w-96">
                        <li className="mb-4 lg:mb-0">
                            <Link to="/features">
                                Features
                                <p className="text-base text-gray-500 lg:hidden">Learn about what Rickshaw offers</p>
                            </Link>
                        </li>
                        <li className="mb-4 lg:mb-0">
                            <Link to="/pricing">
                                Pricing
                                <p className="text-base text-gray-500 lg:hidden">Find our about our pricing</p>
                            </Link> 
                        </li>
                        <li className="mb-4 lg:mb-0">
                            <Link to="/use-cases">
                                Use cases
                                <p className="text-base text-gray-500 lg:hidden">See how you can use Rickshaw</p>
                            </Link> 
                        </li>
                        <li className="mb-4 lg:mb-0">
                            <Link to="/support">
                                Support
                                <p className="text-base text-gray-500 lg:hidden">Get help with the platform</p>
                            </Link> 
                        </li>
                        <li className="mb-4 lg:mb-0 lg:hidden">
                        <a href="https://confirmsubscription.com/h/d/7C7BF2C5C5C65206">
                            Early access
                            <p className="text-base text-gray-500 lg:hidden">Get early access to Rickshaw</p>
                        </a> 
                        </li>
                    </ul>
                </nav>
                <div className="hidden lg:block">
                <a className={`px-8 py-3 border-2 rounded-md cursor-pointer`} href="https://confirmsubscription.com/h/d/7C7BF2C5C5C65206">
                        Early access
                    </a>
                </div>
            </header>
        </div>
    );
};

export default Header;
